<template>
  <div>
    <hr class="ma-1">

    <p class="ma-2">
      <b>State:</b> {{ proposalState }}
    </p>

    <hr class="ma-1">

    <template v-if="editable">
      <b-btn :variant="btnVariant"
             class="w-100 mt-3"
             @click="trySavingProposal">
        <span v-text="btnText"/>
      </b-btn>

      <b-btn v-if="isMissingListEmpty || !isSubmitted"
             v-b-tooltip.hover
             class="pl-1 pr-1 mt-3"
             style="width: 100%"
             title="Download saved proposal as pdf"
             variant="info"
             @click="saveProposalAndPdf">
        <span v-if="isSubmitted"> SAVE, RESUBMIT <br> AND PDF</span>
        <span v-else> SAVE AND PDF</span>
      </b-btn>
    </template>

    <template v-else>
      <b-btn :variant="btnVariant"
             class="w-100 mt-3"
             @click="generate_pdf(proposalId)">
        <span>GENERATE PDF</span>
      </b-btn>
    </template>
  </div>
</template>

<script>
  import { emitSaveProposal } from '@/helpers/proposalForm';

  export default {
    name: 'SynchrotronProposalFormMenuActions',
    model: { prop: 'tabIndex' },
    props: {
      editable: {
        type: Boolean,
        required: true,
      },
      isMissingListEmpty: {
        type: Boolean,
        required: true,
      },
      isSubmitted: {
        type: Boolean,
        required: true,
      },
      lastTabIndex: {
        type: Number,
        default: 3,
      },
      proposalId: {
        type: String,
        required: true,
      },
      proposalState: {
        type: String,
        required: true,
      },
      tabIndex: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        btnVariant: 'info',
        btnText: '',
      };
    },
    watch: {
      isMissingListEmpty: {
        handler() {
          this.setButtonTextAndVariant();
        },
        deep: true,
      },
    },
    methods: {
      emitSaveProposal,
      informRequiredFieldsMissing() {
        this.$emit('input', this.lastTabIndex);
        this.$notify({
          type: 'error',
          title: 'Not all required fields have been filled. Proposal couldn\'t be resubmitted.',
        });
      },
      saveProposalAndPdf() {
        this.emitSaveProposal();
        this.generate_pdf(this.proposalId);
      },
      setButtonTextAndVariant() {
        this.btnVariant = 'info';
        if (this.isSubmitted) {
          if (!this.isMissingListEmpty) {
            this.btnVariant = 'warning';
            this.btnText = 'FILL ALL REQUIRED FIELDS';
          } else {
            this.btnText = 'SAVE AND RESUBMIT';
          }
        } else {
          this.btnText = 'SAVE';
        }
      },
      trySavingProposal() {
        if (this.proposalState === 'submitted' && !this.isMissingListEmpty) {
          this.informRequiredFieldsMissing();
        } else {
          this.emitSaveProposal();
        }
      },
    },
  };
</script>

<style scoped>

</style>
