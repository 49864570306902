<template>
  <base-icon-button :title="title"
                    class="base-round-btn"
                    v-on="$listeners">
    <slot/>
  </base-icon-button>
</template>

<script>
  import BaseIconButton from '@/components/baseComponents/baseIcons/BaseIconButton';

  export default {
    name: 'BaseIconRoundButton',
    components: { BaseIconButton },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style lang="scss" scoped>
  $round-size: 2rem;

  .base-round-btn {
    color: white;
    height: $round-size;
    width: $round-size;
    border: none;
    border-radius: 50%;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2),
    0 6px 10px 0 rgba(0, 0, 0, .14),
    0 1px 18px 0 rgba(0, 0, 0, .12);
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin: 0;
    outline: 0;
  }

  .btn:active:focus {
    opacity: 30%;
    filter: brightness(150%);
    box-shadow: none !important;
    transition: all 0.2s ease-out;
  }

  .btn:focus {
    color: white;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
  }
</style>
