<template>
  <b-card body-bg-variant="light"
          header="Experimental infrastructure"
          header-bg-variant="dark"
          header-text-variant="light">

    <label>Beamline</label>
    <b-form-select v-model="data.general.beamlines"
                   :disabled="!editable"
                   @input.native="input_beamlines">
      <option v-for="option in Object.keys(beamlines)"
              :key="option"
              :value="option">{{ option }}
      </option>
    </b-form-select>

    <label>End station</label>
    <b-form-select v-model="data.general.end_station"
                   :disabled="!editable"
                   @input.native="input_end_station">
      <option v-for="option in beamlines[data.general.beamlines]"
              :key="option"
              :value="option">{{ option }}
      </option>
    </b-form-select>

    <template v-if="isProposalRapidAccess">
      <label for="shiftsMinimal"
             style="width: 100%">
        Minimal shifts requested
        <span class="grey--text">
          (1 to {{ maxShiftsAllowed }})
        </span>
        <b-form-input id="shiftsMinimal"
                      v-model="data.general.shifts_minimal"
                      :class="{ 'invalid': $data.$v.data.general.shifts_minimal.$error }"
                      :max="maxShiftsAllowed"
                      :readonly="!editable"
                      min="1"
                      placeholder="Enter missing value"
                      type="number"
                      @input="inputRapidAccessShifts"
                      @paste.prevent/>
      </label>

      <label for="shiftsOptimal"
             style="width: 100%">
        Optimal shifts requested
        <span class="grey--text">
          (1 to {{ maxShiftsAllowed }})
        </span>
        <b-form-input id="shiftsOptimal"
                      v-model="data.general.shifts_optimal"
                      :class="{ 'invalid': $data.$v.data.general.shifts_optimal.$error }"
                      :max="maxShiftsAllowed"
                      :readonly="!editable"
                      min="1"
                      placeholder="Enter missing value"
                      type="number"
                      @input="inputRapidAccessShifts"
                      @paste.prevent/>
      </label>
    </template>

    <template v-else>
      <label for="shiftsRequested"
             style="width: 100%">
        Shifts requested
        <span class="grey--text">
          (1 to {{ maxShiftsAllowed }})
        </span>
        <b-form-input id="shiftsRequested"
                      v-model="data.general.shifts"
                      :class="{ 'invalid': $data.$v.data.general.shifts.$error }"
                      :max="maxShiftsAllowed"
                      :readonly="!editable"
                      min="1"
                      placeholder="Enter missing value"
                      type="number"
                      @input="inputShifts"
                      @paste.prevent/>
      </label>

      <shifts-schedule v-if="isProposalLongTerm"
                       v-model="data.general.shifts_schedule"
                       :editable="editable"
                       :shiftsScheduleValidation="$data.$v.data.general.shifts_schedule"
                       infrastructure="synchrotron"/>
    </template>

  </b-card>
</template>

<script>
  import ShiftsSchedule from '@/components/proposal/ShiftsSchedule';
  import { isProposalOfType } from '@/helpers/proposalForm';
  import beamlines from '@/json/beamlines';
  import { longTermSpecification, proposalTypesWithMaxShifts } from '@/json/beamlineSpecification';

  export default {
    name: 'ExperimentalInfrastructureForm',
    components: { ShiftsSchedule },
    props: {
      value: undefined,
      validation: undefined,
      editable: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        beamlines,
        data: this.value,
        $v: this.validation,
        proposalTypesWithMaxShifts,
        longTermSpecification,
      };
    },
    computed: {
      maxShiftsAllowed() {
        const currentProposalTypeMaxShifts = this.proposalTypesWithMaxShifts[this.data.general.proposal_type];
        const maxShiftsDefault = this.proposalTypesWithMaxShifts.Standard;
        return currentProposalTypeMaxShifts || maxShiftsDefault;
      },
      isProposalLongTerm() {
        return isProposalOfType(this.data.general, 'Long term');
      },
      isProposalRapidAccess() {
        return isProposalOfType(this.data.general, 'Rapid access');
      },
    },
    methods: {
      input_beamlines() {
        this.reset_endstation();
        this.$data.$v.data.general.beamlines.$touch();
      },
      input_end_station() {
        this.reset_beamline_tab();
        this.$data.$v.data.general.end_station.$touch();
      },
      reset_endstation() {
        if (this.data.general.end_station !== undefined) this.data.general.end_station = '';
        this.data.environment = {};
      },
      reset_beamline_tab() {
        if (this.data.general.end_station !== undefined) this.data.environment = {};
      },
      inputShifts() {
        this.$data.$v.data.general.shifts.$touch();
        this.$data.$v.data.general.shifts_schedule.$touch();
      },
      inputRapidAccessShifts() {
        this.$data.$v.data.general.shifts_minimal.$touch();
        this.$data.$v.data.general.shifts_optimal.$touch();
      },
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) this.data.general = {};
        },
        deep: true,
      },
      'data.general.beamlines': {
        handler() {
          const chosenBeamlineEndStations = this.beamlines[this.data.general.beamlines];
          if (chosenBeamlineEndStations.length === 1) {
            this.$set(this.data.general, 'end_station', ...chosenBeamlineEndStations);
          }
        },
      },
      isProposalRapidAccess: {
        handler(newValue) {
          if (newValue) {
            delete this.data.general.shifts;
          } else {
            delete this.data.general.shifts_minimal;
            delete this.data.general.shifts_optimal;
          }
        },
      },
      isProposalLongTerm: {
        handler(newValue) {
          if (!newValue) {
            delete this.data.general.shifts_schedule;
          } else if (!this.data.general.shifts_schedule) {
            const emptyShiftsSchedule = Array(this.longTermSpecification.numberOfCallsToPlan);
            this.$set(this.data.general, 'shifts_schedule', emptyShiftsSchedule);
          }
        },
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }
</style>
