<!--suppress ALL -->
<template>
  <div id="general-part">

    <general_information v-model="data"
                         :editable="editable"
                         :infrastructure="infrastructure"
                         :validation="$v"></general_information>

    <b-card body-bg-variant="light"
            header="Team"
            header-bg-variant="dark"
            header-text-variant="light">
      <b-row align-h="center">
        <team_form v-if="proposalObjectId"
                   :editable="editable"
                   :proposal-object-id="proposalObjectId"/>
        <b-spinner v-else
                   variant="info"/>
      </b-row>
    </b-card>

    <affiliation v-model="data"
                 :editable="editable"
                 @save_proposal="save_proposal"></affiliation>

    <experiment_discipline v-model="data"
                           :disciplines_prop="disciplines"
                           :editable="editable"
                           :validation="$v"></experiment_discipline>

    <experimental-infrastructure-form v-model="data"
                                      :editable="editable"
                                      :validation="$v"/>

    <scientific-part v-model="data"
                     :editable="editable"
                     :validation="$v"/>

    <equipment v-model="data"
               :editable="editable"
               :validation="$v"></equipment>

    <publications-section v-model="data"
                          :editable="editable"
                          :validation="$v"/>

    <additional_info v-model="data"
                     :editable="editable"
                     :validation="$v"></additional_info>

    <preferred_time v-model="data"
                    :editable="editable"></preferred_time>

  </div>
</template>

<script>
  import team_form from '@/components/team_form';
  import { proposal_validator } from '@/assets/validators/proposal_validator';
  import equipment from '@/components/proposal/proposals/general/equipment/equipment';
  import affiliation from '@/components/proposal/proposals/general/affiliation/affiliation';
  import ExperimentalInfrastructureForm
    from '@/components/proposal/proposals/general/experimentalInfrastructure/ExperimentalInfrastructureForm';
  import PublicationsSection from '@/components/proposal/proposals/general/publications/PublicationsSection';
  import preferred_time from '@/components/proposal/proposals/general/preferred_time/preferred_time';
  import additional_info from '@/components/proposal/proposals/general/additional_info/additional_info';
  import ScientificPart from '@/components/proposal/proposals/general/scientific_part/ScientificPart';
  import general_information from '@/components/proposal/proposals/general/general_information/general_information';
  import experiment_discipline
    from '@/components/proposal/proposals/general/experiment_discipline/experiment_discipline';
  import disciplines from '@/json/disciplines';
  import { hasProperty } from '@/helpers';

  export default {
    props: {
      value: undefined,
      editable: undefined,
      infrastructure: undefined,
    },
    components: {
      team_form,
      ScientificPart,
      equipment,
      PublicationsSection,
      affiliation,
      additional_info,
      preferred_time,
      general_information,
      ExperimentalInfrastructureForm,
      experiment_discipline,
    },
    data() {
      return {
        data: {
          general: {
            synchrotron_experience_bool: '',
            preferred_time: [],
            undesirable_period: [],
          },
          team: [],
        },
        disciplines,
      };
    },
    computed: {
      proposalObjectId() {
        return hasProperty(this.value, '_id') ? this.value._id.$oid : '';
      },
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) {
            this.data.general = {};
          }
        },
        deep: true,
      },
    },
    methods: {
      save_proposal() {
        this.$emit('save_proposal');
      },
    },
    validations: proposal_validator,
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
  }
</style>
