<template>
  <div id="proposal">

    <b-tabs v-model="tabIndex"
            :nav-wrapper-class="{'nav-wrapper-document': true}"
            card
            end
            pills
            vertical>
      <b-tab active
             title="GENERAL">
        <general-part v-model="data"
                      :editable="editable"
                      :infrastructure="infrastructure"
                      @save_proposal="save_data"/>
      </b-tab>
      <b-tab title="BEAMLINE">
        <span v-if="endStation">
          <component :is="beamlineTabComponentName"
                     v-model="data.environment"
                     :editable="editable"
                     @beamline_validation="update_beamline_validator($event)"/>
        </span>
        <span v-if="!endStation">
          <b-card body-bg-variant="light"
                  header="Please make sure environment is selected on 'general' page"
                  header-bg-variant="danger"
                  header-class="color-lighten"
                  header-text-variant="light">
          </b-card>
        </span>
      </b-tab>
      <b-tab title="SAMPLES">
        <samples-part v-model="data.samples"
                      :editable="editable"
                      @sample_validation="update_sample_validator($event)"
                      @save_document="save_data"/>
      </b-tab>
      <b-tab title="SUBMIT"
             @click="checkMissingFields">
        <submit-part v-model="data"
                     :editable="editable"
                     :missing_list="missingList"
                     :infrastructure="infrastructure"
                     @submit_validation="updateSubmitValidator($event)"/>
      </b-tab>
      <template v-slot:tabs-end>
        <synchrotron-proposal-form-menu-actions v-model="tabIndex"
                                                :editable="editable"
                                                :is-missing-list-empty="isMissingListEmpty"
                                                :is-submitted="is_submitted"
                                                :proposalId="proposalId"
                                                :proposal-state="proposalState"
                                                @save-proposal="save_data"/>

      </template>
    </b-tabs>

    <proposal-form-footer v-model="tabIndex"
                          :end-station="endStation"
                          :is-missing-list-empty="isMissingListEmpty"
                          :proposal="data"
                          :proposal-state="proposalState"
                          @load-data="loadData"/>

  </div>
</template>

<script>
  import { proposal_validator } from '@/assets/validators/proposal_validator';
  import mapOfRemainingFieldsToSubmitErrorsSynchrotron from '@/json/mapOfRemainingFieldsToSubmitErrorsSynchrotron';
  import { missingFields } from '@/mixins/missingFields';
  import { proposalForm } from '@/mixins/proposalForm';
  import ProposalFormFooter from '@/components/proposal/ProposalFormFooter';
  import SynchrotronProposalFormMenuActions from '@/components/proposal/synchrotron/SynchrotronProposalFormMenuActions';
  import generalPart from './proposal/proposals/general';

  export default {
    name: 'SynchrotronProposalForm',
    components: {
      ProposalFormFooter,
      SynchrotronProposalFormMenuActions,
      generalPart,
      demeterpeemPart: () => import('./proposal/proposals/EndStationDForm'),
      pirxuhvsoftxasPart: () => import('./proposal/proposals/EndStationCForm'),
      uranosarpesPart: () => import('./proposal/proposals/EndStationBForm'),
      phelixpesxasPart: () => import('./proposal/proposals/bm_phelix'),
      astratenderandhardxafsPart: () => import('./proposal/proposals/EndStationFForm'),
      demeterstxmPart: () => import('./proposal/proposals/EndStationEForm'),
      polyxuctuxrfuxasPart: () => import('./proposal/proposals/EndStationHForm'),
      cirissnomafmirPart: () => import('./proposal/proposals/EndStationIForm'),
      cirioptirPart: () => import('./proposal/proposals/EndStationJForm'),
      ciriftirPart: () => import('./proposal/proposals/BmFtIrMicroscope'),
      samplesPart: () => import('./proposal/proposals/samples_part'),
      submitPart: () => import('./proposal/proposals/submit_part'),
    },
    mixins: [
      missingFields,
      proposalForm,
    ],
    data() {
      return { infrastructure: 'synchrotron' };
    },
    validations() {
      return proposal_validator;
    },
    computed: {
      proposalId() {
        return this.data._id?.$oid || '';
      },
      missingList() {
        return this.process_missing_fields(this.missingFields, mapOfRemainingFieldsToSubmitErrorsSynchrotron);
      },
      is_submitted() {
        return this.proposalState === 'submitted';
      },
      endStation() {
        return this.data.general.end_station || '';
      },
      beamlineTabComponentName() {
        const endStationContinuousText = this.endStation.replaceAll(' ', '').replaceAll('-', '').replaceAll('/', '');
        return `${endStationContinuousText.toLowerCase()}-part`;
      },
    },
    methods: {
      update_beamline_validator($event) {
        this.$v.data.environment = $event;
        this.checkMissingFields();
      },
      update_sample_validator($event) {
        this.$v.data.samples = $event;
        this.checkMissingFields();
      },
      save_data() {
        return this.axios.post(`/documents/${this.$route.params.id}`, this.data)
          .then((resp) => {
            if (resp.status === 200) {
              if (this.is_submitted) {
                this.$notify({
                  type: 'success',
                  title: 'Resubmitted!',
                });
              }

              this.$notify({
                type: 'success',
                title: 'Saved successfully!',
              });
            }
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Could not save!',
            });
          });
      },
    },
    watch: {
      $v: {
        handler() {
          setTimeout(() => {
            this.checkMissingFields();
          }, 0);
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss">
  $md-width: 1450px;
  $sm-width: 575px;

  #proposal {
    .col .card {
      min-width: 20rem;
      margin: 1.5rem 18rem 1.5rem 2rem;
      text-align: left;
      @media (max-width: $md-width) {
        margin: 0 12rem 0 0;
      }
      @media (max-width: $sm-width) {
        margin: 0 10rem 0 0;
      }
    }

    .card .col .card-header {
      border: none;
      background-color: white;
      font-weight: bold;
      font-size: 1.5rem;
    }

    label {
      font-weight: bold;
    }

    input, select, textarea, .input-group, .btn-group {
      input {
        margin-bottom: 0;
      }

      margin-bottom: 1.7rem;
    }

    .color-lighten {
      opacity: 0.8;
    }

    .nav-wrapper-document {
      position: fixed !important;
      right: 1.5rem !important;
      margin-top: 2.5rem;
      width: 15rem !important;
      background-color: white;
      border-radius: 0.2rem;
      visibility: visible;
      @media (max-width: $md-width) {
        width: unset !important;
        max-width: 11rem;
        margin-top: 1.25rem;
        opacity: 0.8;
      }

      .nav-link {
        color: black;
        margin-bottom: 0.1rem;

        &:hover {
          color: black;
          background-color: #cbd5dd !important;
        }

        &.active {
          color: white;
          background-color: #6c757d !important;
        }
      }
    }

  }

</style>
