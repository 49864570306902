<template>
  <b-card body-bg-variant="light"
          header="Preferred time of the experiment"
          header-bg-variant="dark"
          header-text-variant="light">
    <date-range v-model="data.general.preferred_time"
                :disabled="!editable"
                title="Preferred time"
                @input="compare_dates($event, 0)"></date-range>
    <hr>
    <date-range v-model="data.general.undesirable_period"
                :disabled="!editable"
                title="Undesirable period"
                @input="compare_dates($event, 1)"></date-range>
  </b-card>
</template>

<script>
  import dateRange from '@/components/proposal/proposals/date_range';
  import moment from 'moment';

  export default {
    name: 'preferred_time',
    props: {
      value: undefined,
      editable: {
        type: Boolean,
        default: true,
      },
    },
    components: { dateRange },
    data() {
      return { data: this.value };
    },
    methods: {
      compare_dates(event, component) {
        if (event) {
          let event_dates_range = [];
          let dates_to_compare_range = [];
          let same_date = false;
          const undesirable = this.data.general.undesirable_period;
          const preferred = this.data.general.preferred_time;
          if (undesirable && preferred && undesirable.length !== 0 && preferred.length !== 0) {
            // event is returned as array so it is iterated
            event.forEach((el) => {
              const dates_to_compare = (
                component === 0 ? this.data.general.undesirable_period : this.data.general.preferred_time
              );
              event_dates_range = this.get_dates_range(el[0], el[1]);
              dates_to_compare.forEach((ele) => {
                dates_to_compare_range = this.get_dates_range(ele[0], ele[1]);
                if (same_date === false) {
                  same_date = dates_to_compare_range.some((r) => event_dates_range.includes(r));
                }
              });
            });
          }
          if (same_date) {
            this.$notify({
              type: 'error',
              title: 'Preferred time and undesirable period dates are identical ',
            });
          }
        }
      },
      get_dates_range(start_date, stop_date) {
        // build array of days between start_date and stop_date included, ex. [a,d] to [a,b,c,d]
        const date_array = [];
        let current_date = moment(start_date);
        const stop_date_moment = moment(stop_date);
        while (current_date <= stop_date_moment) {
          date_array.push(moment(current_date).format('YYYY-MM-DD'));
          current_date = moment(current_date).add(1, 'days');
        }
        return date_array;
      },
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) this.data.general = {};
        },
        deep: true,
      },
      'data.general.preferred_time': {
        handler() {
          if (this.data.general.preferred_time?.length === 0) {
            delete this.data.general.preferred_time;
          }
        },
      },
      'data.general.undesirable_period': {
        handler() {
          if (this.data.general.undesirable_period?.length === 0) {
            delete this.data.general.undesirable_period;
          }
        },
      },
    },
  };
</script>

<style scoped>

</style>
