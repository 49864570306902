<template>
  <div>
    <label>
      <span v-text="title"/>
      <base-material-design-icon v-b-tooltip.right
                                 :title="tooltipTitle"
                                 icon="info"/>
      <span v-if="!is_required"
            class="grey--text font-italic"> - Optional</span>
    </label>
    <datepicker v-for="(val, index) in value"
                :key="index"
                v-model="value[index]"
                :min="min_date"
                :read-only="disabled"
                class="mt-3 mb-3"
                range
                typeof="datetime"
                @input="removeEmpty"/>
    <div v-if="!disabled"
         style="margin-bottom: 7rem;">
      <base-icon-round-button-add class="float-right mt-4"
                                  @click="addEmpty"/>
    </div>
  </div>
</template>

<script>
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';
  import BaseIconRoundButtonAdd from '@/components/baseComponents/baseIcons/BaseIconRoundButtonAdd';

  export default {
    components: {
      BaseMaterialDesignIcon,
      BaseIconRoundButtonAdd,
    },
    props: {
      value: '',
      disabled: false,
      title: '',
      is_required: Boolean,
    },
    data() {
      return {
        valueFromProp: [],
        min_date: this.$moment(new Date()).format('YYYY-MM-DD'),
        tooltipTitle: 'Click once on day to choose starting date and once to choose ending date',
      };
    },
    methods: {
      addEmpty() {
        if (this.valueFromProp === undefined) {
          this.valueFromProp = [];
        }
        this.valueFromProp.push(['', '']);
      },
      removeEmpty() {
        if (event !== undefined) {
          if (event.type === 'click') {
            for (let i = 0; i < this.valueFromProp.length; i++) {
              if (this.valueFromProp[i] !== undefined || this.valueFromProp[i] !== null) {
                if (this.valueFromProp[i][0] === '' && this.valueFromProp[i][1] === '') {
                  this.valueFromProp.splice(i, 1);
                }
              }
            }
          }
        }
      },
    },
    watch: {
      value() {
        this.valueFromProp = this.value;
      },
      valueFromProp() {
        this.$emit('input', this.valueFromProp);
      },
    },
  };
</script>

<style scoped>
  label {
    font-weight: bold;
  }
</style>
