import { between, maxLength, minLength, numeric, required, requiredIf } from 'vuelidate/lib/validators';
import {
  areAllShiftsScheduled,
  hasPreviousConnotations,
  isAnyThesisSelected,
} from '@/assets/validators/validator_helpers';
import { longTermSpecification, proposalTypesWithMaxShifts } from '@/json/beamlineSpecification';
import { isProposalOfType } from '@/helpers/proposalForm';
import _ from 'lodash';

const isMinLowerThanOptimal = (value, siblings) => {
  if (isProposalOfType(siblings, 'Rapid access')) {
    return Number(siblings.shifts_minimal) <= Number(siblings.shifts_optimal);
  }
  return true;
};

const areShiftsInRange = (value, siblings) => {
  if (value) {
    const maxShiftsAllowed = proposalTypesWithMaxShifts[siblings.proposal_type];
    if (maxShiftsAllowed) {
      return _.inRange(value, 1, maxShiftsAllowed + 1);
    }
  }
  return true;
};

const proposal_validator = {
  data: {
    title: { required },
    general: {
      keywords: {},
      proposal_type: { required },
      is_long_term_to_standard_transfer_consent: {
        requiredIf: requiredIf(function isProposalLongTerm() {
          return isProposalOfType(this.data.general, 'Long term');
        }),
      },
      rapid_access_subtype: {
        requiredIf: requiredIf(function isProposalRapidAccess() {
          return isProposalOfType(this.data.general, 'Rapid access');
        }),
      },
      is_rapid_access_force_majeure: {
        requiredIf: requiredIf(function isProposalRapidAccess() {
          return isProposalOfType(this.data.general, 'Rapid access');
        }),
      },
      is_rapid_access_perform_remotely_requested: {
        requiredIf: requiredIf(function isProposalRapidAccessForceMajeure() {
          return this.data.general.is_rapid_access_force_majeure;
        }),
      },
      affiliation: { required },
      abstract: {},
      industrial_sponsorship: { required },
      beamlines: { required },
      end_station: { required },
      category: { required },
      previous_proposals: {
        requiredIf: requiredIf(function checkPreviousConnotations() {
          return hasPreviousConnotations(this.data);
        }),
      },
      shifts: {
        requiredIf: requiredIf(function isProposalStandard() {
          return !isProposalOfType(this.data.general, 'Rapid access');
        }),
        areShiftsInRange,
        numeric,
      },
      shifts_minimal: {
        requiredIf: requiredIf(function isProposalRapidAccess() {
          return isProposalOfType(this.data.general, 'Rapid access');
        }),
        isMinLowerThanOptimal,
        areShiftsInRange,
        numeric,
      },
      shifts_optimal: {
        requiredIf: requiredIf(function isProposalRapidAccess() {
          return isProposalOfType(this.data.general, 'Rapid access');
        }),
        isMinLowerThanOptimal,
        areShiftsInRange,
        numeric,
      },
      shifts_schedule: {
        requiredIf: requiredIf(function isProposalLongTerm() {
          return isProposalOfType(this.data.general, 'Long term');
        }),
        minLength: minLength(longTermSpecification.numberOfCallsToPlan),
        maxLength: maxLength(longTermSpecification.numberOfCallsToPlan),
        areAllShiftsScheduled,
        $each: {
          requiredIf: requiredIf(function isProposalLongTerm() {
            return isProposalOfType(this.data.general, 'Long term');
          }),
          numeric,
          between: between(longTermSpecification.minShiftsPerCall, longTermSpecification.maxShiftsPerCall),
        },
      },
      scientific_background: {},
      motivation: {},
      experimental_plan: {},
      request_justification: {},
      results_expected: {},
      references: {},
      experiment_experience: { required },
      experience_description: {
        requiredIf: requiredIf(function experience_description_check() {
          return this.data.general.experiment_experience;
        }),
      },
      use_solaris_lab: { required },
      discipline: { required },
      discipline_specific: { required },
      attachments: { required },
      process_in_lab: {},
      solaris_equipment: {},
      user_equipment: {},
      any_publications_related_to_solaris: { required },
      publications: { requiredIf: requiredIf((val) => val.any_publications_related_to_solaris) },
      any_diploma_theses_related_to_proposal: { required },
      number_of_diploma_theses: {
        requiredIf: requiredIf((val) => val.any_diploma_theses_related_to_proposal),
        isAnyThesisSelected,
      },
    },
  },
};

export { proposal_validator };
